<template>
  <div>
    <Navbar />
    <v-container class="settings">
      <h1>Settings</h1>
      <v-container class="my-5">
        <v-layout row wrap justify-center class="mb-5">
          <!-- User Settings -->
          <v-flex xs12 md6 lg7 xl8 class="mx-1 mb-5">
            <v-card class="pa-2">
              <v-card-title>
                <h2 class="mb-3">User Settings</h2>
              </v-card-title>
              <v-card-text>
                <v-form class="px-3" ref="data_form">
                  <h3>
                    Current Username:
                    <span class="primary--text">{{ user.username }}</span>
                  </h3>
                  <v-text-field
                    label="Username"
                    v-model="newUsername"
                    prepend-icon="mdi-account"
                  >
                  </v-text-field>
                  <h3>
                    Current Email:
                    <span class="primary--text">{{ user.email }} </span>
                  </h3>
                  <v-text-field
                    label="Email"
                    v-model="newEmail"
                    prepend-icon="mdi-email"
                    type="e-mail"
                  >
                  </v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="primary mx-0 mt-3"
                    @click="changeUserdata"
                    :loading="loading"
                    >Change settings
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
          <!-- Change Password -->
          <v-flex xs12 md6 lg7 xl8 class="mx-1 mb-5">
            <v-card class="pa-2">
              <v-card-title>
                <h2 class="mb-3">Password</h2>
              </v-card-title>
              <v-card-text>
                <v-form class="px-3" ref="pass_form">
                  <!--
                  <h3>Change Password</h3> -->
                  <!-- <v-text-field
                    label="Current password"
                    v-model="currentPassword"
                    prepend-icon="mdi-key"
                    :rules="rules"
                    type="password"
                  >
                  </v-text-field> -->
                  <v-text-field
                    label="New password"
                    v-model="newPassword1"
                    prepend-icon="mdi-account-key"
                    :rules="passwordRules1"
                    type="password"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Repeat password"
                    v-model="newPassword2"
                    prepend-icon="mdi-account-key"
                    :rules="passwordRules2"
                    type="password"
                  >
                  </v-text-field>

                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    class="primary mx-0 mt-3"
                    @click="changePassword"
                    :loading="loading"
                    >Change password
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
          <!-- Subscription -->
          <v-flex xs12 md6 lg7 xl8 class="mx-1 mb-5">
            <v-card class="pa-2">
              <v-card-title>
                <h2 class="mb-3">Subscription model</h2>
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Current model</v-list-item-title>
                      <v-list-item-subtitle>{{
                        subscription.model
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Monthly payment</v-list-item-title>
                      <v-list-item-subtitle
                        >{{ subscription.payment }} €</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Last payment</v-list-item-title>
                      <v-list-item-subtitle>{{
                        subscription.last
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-spacer></v-spacer>
                <SubscriptionPopup />
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import SubscriptionPopup from "@/components/SubscriptionPopup";

export default {
  name: "Settings",
  data() {
    return {
      subscriptionModel: "Free",
      newEmail: "",
      newUsername: "",
      newPassword1: "",
      newPassword2: "",
      passwordRules1: [
        (v) =>
          (v && v.length > 7) ||
          "The password must be at least 8 characters long.",
      ],
      passwordRules2: [
        (v) => (v && v == this.newPassword1) || "The passwords must match.",
      ],
      subscription: {
        model: "pro",
        payment: 20,
        last: "TODAY :D",
      },
      loading: false,

      user: { username: "", email: "" },

      userdataChangeURL: `${process.env.VUE_APP_USERDATA_URL}`,
      passwordChangeURL: `${process.env.VUE_APP_PASSWORD_CHANGE_URL}`,
      getSubscriptionURL: `${process.env.VUE_APP_SUBSCRIPTION_URL}`,
      getUserURL: `${process.env.VUE_APP_GET_USER_URL}`,
    };
  },
  components: {
    SubscriptionPopup,
    Navbar,
  },
  computed: {
    token() {
      return this.$store.getters["user/getTokenValue"];
    },
  },
  methods: {
    async changeUserdata() {
      //checking for valid form
      if (this.$refs.data_form.validate()) {
        await this.sendUserdataChangeRequest();
        this.$refs.data_form.reset();
        //emiting an event for the navbar component from the popup component
        this.$emit("data_changed");
      }
    },
    async changePassword() {
      //checking for valid form
      if (this.$refs.data_form.validate()) {
        await this.sendPasswordChangeRequest();
        this.$refs.pass_form.reset();
        //emiting an event for userinteraction
        this.$emit("data_changed");
      }
    },
    async sendUserdataChangeRequest() {
      const URL = this.$data.userdataChangeURL + this.user.id;
      let request = await fetch(URL, {
        method: "POST",
        withCredentials: true,
        headers: {
          Authorization: this.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.newUsername,
          email: this.newEmail,
        }),
      });
      if (request.status === 200 || request.status === 401) {
        // const text = await request.text();
        // const data = await JSON.parse(text);
        this.reloadPage();
      } else {
        //some other error
        const text = await request.text();
        const data = await JSON.parse(text);
        //TODO: create error response from the server msg
        console.log(data.msg);
        // this.reloadPage();
      }
    },
    async sendPasswordChangeRequest() {
      const URL = this.$data.passwordChangeURL + this.user.id;
      console.log(URL);
      let request = await fetch(URL, {
        method: "POST",
        withCredentials: true,
        headers: {
          Authorization: this.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password1: this.newPassword1,
          password2: this.newPassword2,
        }),
      });
      console.log(request);
      if (request.status === 200 || request.status === 401) {
        // const text = await request.text();
        // const data = await JSON.parse(text);
        this.reloadPage();
      } else {
        //some other error
        const text = await request.text();
        const data = await JSON.parse(text);
        //TODO: create error response from the server msg
        console.log(data.msg);
        this.reloadPage();
      }
    },
    async getSubscriptonModel() {
      let request = await fetch(this.$data.getSubscriptionURL + this.user.id, {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: this.token,
          "Content-Type": "application/json",
        },
      });
      if (request.status === 200 || request.status === 401) {
        const text = await request.text();
        const data = await JSON.parse(text);
        this.subscrtiption = data.subscription;
      } else {
        //some other error
        const text = await request.text();
        const data = await JSON.parse(text);
        //TODO: create error response from the server msg
        console.log(data.msg);
        this.reloadPage();
      }
    },
    async fetchUser(userId) {
      const URL = this.$data.getUserURL + userId;
      let request = await fetch(URL, {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: this.token,
          "Content-Type": "application/json",
        },
      });
      if (request.status === 200 || request.status === 401) {
        const text = await request.text();
        const data = await JSON.parse(text);
        return data;
      } else {
        //some other error
        const text = await request.text();
        const data = await JSON.parse(text);
        //TODO: create error response from the server msg
        console.log(data.msg);
      }
    },
    reloadPage() {
      window.location.reload();
    },
  },
  created: async function () {
    const user = await this.fetchUser(this.$store.state.user.user.id);
    this.user = user.user;
  },
};
</script>