<template>
  <v-dialog max-width="600" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn text slot="activator" class="primary" light v-on="on"
        >Change Subscription</v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        <h2>Change Subscription</h2>
      </v-card-title>
      <v-card-text>
        <h3>Wähle dein Subscription Model aus</h3>
        <v-form class="px-3" ref="form">
          <v-select :items="subModels" :label="subTitle"></v-select>
          <v-checkbox v-model="tos_check" :label="tos_label"></v-checkbox>

          <v-spacer></v-spacer>

          <v-btn
            text
            class="primary mx-0 mt-3"
            @click="submit"
            :loading="loading"
            >Add Project</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export default {
  name: "SubscriptionPopup",
  data() {
    return {
      subTitle: "Subscription Modelle",
      subModels: ["Starter", "Normal", "Pro"],
      tos_check: false,
      tos_label: "Akzeptiere unsere Terms of Service",
      title: "",
      content: "",
      due: null,
      inputRules: [
        (v) =>
          (v && this.tos_check) || "Bitte akzeptiere unsere Terms of Service",
      ],
      loading: false,
      dialog: false,
    };
  },
  methods: {
    submit() {
      //checking for valid form
      if (this.$refs.form.validate()) {
        //saving to the database
        console.log(this.title, this.content);
        this.dialog = false;
        this.$refs.form.reset();
        //emiting an event for the navbar component from the popup component
        this.$emit("projectAdded");
      }
    },
  },
  computed: {
    formattedDate() {
      return this.due ? format(parseISO(this.due), "d MMM yyyy") : "";
    },
  },
};
</script>

<style>
</style>